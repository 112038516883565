import React, { memo } from 'react'
import { Message } from '../../../../types/storage.type'
import { ConversationContentItem } from '../../../../types/conversation.type'

interface IProps {
  message: Message
}

const CustomMessage = ({ message }: IProps) => {
  let messageContent = null
  if (Array.isArray(message.content)) {
    const images = message.content.filter((el: ConversationContentItem) => el.type === 'image_url')
    messageContent = (
      <div>
        <div className="chat-message-images-preview">
          {images?.length
            ? images.map((img) => {
                return (
                  <div key={img.image_url?.url} className="image-preview">
                    <img
                      src={img.image_url?.url}
                      alt="preview}"
                      style={{ width: 'auto', height: 'auto', maxHeight: '60px' }}
                      className="added-image-preview"
                    />
                  </div>
                )
              })
            : null}
        </div>
      </div>
    )
  }
  return <div>{messageContent}</div>
}

export default memo(CustomMessage)
