import React from 'react'
import { Tabs, TabsProps } from 'antd'
import ReviewAllDataTab from './components/ReviewAllDataTab'
import AddNewDataTab from './components/AddNewDataTab'
import SearchAndEditTab from './components/SearchAndEditTab'
import SettingsTab from './components/Settings/SettingsTab'
import './knowledgeBase.less'
import { useAppSelector } from '../../hooks/appHook'

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'Add New Data',
    children: <AddNewDataTab />,
  },
  {
    key: '2',
    label: 'Search & Edit Data',
    children: <SearchAndEditTab />,
  },
  {
    key: '3',
    label: 'Review All Data',
    children: <ReviewAllDataTab />,
  },
  {
    key: '4',
    label: 'Settings',
    children: <SettingsTab />,
  },
]

const KnowledgeBase = () => {
  /** Storage */
  const { storages } = useAppSelector((state) => state.storage)

  return (
    <div className="container knowledge-base-container">
      {storages.length ?
        <Tabs defaultActiveKey="1" items={items} destroyInactiveTabPane /> :
        <div className="empty-storages-container">
          To continue working, it is necessary to have at least one created storage. To create a storage,
          please click the “Create new storage” button.
        </div>}
    </div>
  )
}

export default KnowledgeBase
