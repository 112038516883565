import React, { useMemo, memo } from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { Message } from '../../../../types/storage.type'
import { ConversationContentItem } from '../../../../types/conversation.type'
import CustomMessage from '../CustomMessage/CustomMessage'
import Logo from '../../../../components/UI/Logos/Logo'

interface IProps {
  message: Message
}

const ReactMarkdownContent = ({ message }: IProps) => {
  const isIncludesImages = useMemo(() => {
    if (Array.isArray(message.content)) {
      return !!message.content?.find((el: ConversationContentItem) => el.type === 'image_url')
    }
    return false
  }, [message])

  return (
    <div id={message.role + '-paragraph-container'}>
      <h4>{message.role === 'tool' ? 'function response' : message.role}</h4>
      {message.tool_calls?.length ? (
        <div className="paragraph function-call-paragraph">
          <h5>function_call {message.tool_calls[0].function.name}</h5>
          <p> ({message.tool_calls[0].function?.arguments})</p>
        </div>
      ) : (
        <p className={`paragraph ${isIncludesImages && 'paragraph-images'}`} id={'paragraph-' + message.role}>
          {message.role === 'assistant' && (
            <div className="logo-container">
              <Logo name={'safetyLensDefault'} width={'100%'} height={'60'} />
            </div>
          )}
          {message.content &&
            message.role !== 'system' &&
            (message.role === 'user' ? (
              <CustomMessage message={message} />
            ) : (
              <ReactMarkdown>{message.content as string}</ReactMarkdown>
            ))}
        </p>
      )}
    </div>
  )
}

export default memo(ReactMarkdownContent)
