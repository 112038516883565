import React, { useCallback, useState, memo } from 'react'
import { Button, Popover } from 'antd'
import { PaperClipOutlined } from '@ant-design/icons'
import { useAppStateActions } from '../../../../hooks/actions'
import { useDispatch } from 'react-redux'
import { useDropzone } from 'react-dropzone'

interface IProps {
  isFullSelectedImages: boolean
  disabled: boolean
  onDrop: (files: Array<File>) => void
}

const AddImagesPopover = ({ isFullSelectedImages, onDrop, disabled }: IProps) => {
  const dispatch = useDispatch()

  /** State */
  const [isOpenPopover, setIsOpenPopover] = useState(false)

  /** Use custom hooks */
  const { setAddImageByUrlModalOpen } = useAppStateActions()
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/webp': [],
      'image/gif': [],
    },
    maxSize: 20 * 1024 * 1024,
  })

  const onClickSelectByLinkImage = useCallback(() => {
    dispatch(setAddImageByUrlModalOpen(true))
    setIsOpenPopover(false)
  }, [])

  const onClickSelectFromFSImage = useCallback(() => {
    setIsOpenPopover(false)
  }, [])

  return (
    <Popover
      open={isOpenPopover && !isFullSelectedImages}
      content={
        <div className="attach-image-popover">
          <Button block className="popover-button attach-image-by-link-button" onClick={onClickSelectByLinkImage}>
            Upload via link
          </Button>
          <div className="upload-dropzone" {...getRootProps()}>
            <input {...getInputProps()} />
            <Button className="popover-button attach-image-by-fs-button" onClick={onClickSelectFromFSImage}>
              Upload from device
            </Button>
          </div>
        </div>
      }
      trigger="click"
      onOpenChange={(newVisible) => setIsOpenPopover(newVisible)}
    >
      <Button
        className={`attach-images-button ${isFullSelectedImages && 'attach-images-button-disabled'}`}
        shape="circle"
        disabled={isFullSelectedImages || disabled}
        icon={<PaperClipOutlined style={{ fontSize: '20px' }} />}
        onClick={() => setIsOpenPopover(true)}
      ></Button>
    </Popover>
  )
}

export default memo(AddImagesPopover)
