import React from 'react'
import ReactGA from 'react-ga4'
import { ConfigProvider } from 'antd'
import { StyleProvider } from '@ant-design/cssinjs'
import { theme } from './theme/theme'
import { IS_PRODUCTION } from './config/consts'
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './components/AppRouter'

IS_PRODUCTION && ReactGA.initialize('G-2JFDHFP0Z1')

function App() {
  console.log(process.env.NODE_ENV)
  return (
    <BrowserRouter>
      <StyleProvider hashPriority={'high'}>
        <ConfigProvider theme={theme}>
          <AppRouter />
        </ConfigProvider>
      </StyleProvider>
    </BrowserRouter>
  )
}

export default App
