import React from 'react'
import { Button, Form, Input } from 'antd'
import ResetPassword from './ResetPassword'
import { useLocation } from 'react-router-dom'
import Icon from '../../components/UI/Icons/Icons'
import TopFloater from './TopFloater'
import { useErrorMessage } from '../../hooks/useErrorMessage'
import { useResetPasswordMutation } from '../../store/api/auth.api'
import AppPrimaryButton from '../../components/UI/AppPrimaryButton/AppPrimaryButton'
import { useSuccessMessage } from '../../hooks/useSuccessMessage'
import { requiredValidator } from '../../helpers/validators'
import Logo from '../../components/UI/Logos/Logo'

const ForgotPassword = () => {
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const code = params.get('code')
  const email = params.get('email')

  const [resetPassword, { error, isSuccess, isLoading }] = useResetPasswordMutation()

  const onFinish = (values: { email: string }) => {
    resetPassword(values)
  }

  useErrorMessage('Invalid credentials', error)
  useSuccessMessage('Please, check your email inbox to proceed with Password Reset. Thank you!', isSuccess)

  if (code && email) {
    return <ResetPassword code={code} email={email} />
  }

  return (
    <>
      <TopFloater isSignup />
      <Button id="logo-btn" type="link" href="/signin" className="logo">
        <Logo name={'safetyLensDefault'} />
      </Button>
      <div className="login-box login-box-forgot-password">
        <Form name="login-form" layout="vertical" onFinish={onFinish} className="form-forgot-password">
          <div>
            <p className="form-title forgot-password-form-title">Forgot your password?</p>
            <p>
              No worries. Enter the email address associated with your account and we’ll send you a secure reset link.
            </p>
          </div>
          <div>
            <div className="form-input-label">Type your email</div>
            <Form.Item
              name="email"
              rules={[
                { required: true, validator: requiredValidator('Email is required!') },
                { type: 'email', message: 'Please enter valid email address!' },
              ]}
            >
              <Input size="large" placeholder="Email" />
            </Form.Item>
          </div>
          <Form.Item className="form-item-forgot-password-button-wrapper">
            <AppPrimaryButton size="middle" type="primary" htmlType="submit" loading={isLoading}>
              Next
              <Icon name={'buttonRight'} />
            </AppPrimaryButton>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}

export default ForgotPassword
