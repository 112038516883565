import React from 'react'
import SignIn from '../routes/guest/SignIn'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useAppSelector } from '../hooks/appHook'
import MainLayout from '../layouts/mainLayout/MainLayout'
import GuestLayout from '../layouts/guestLayout/GuestLayout'
import SignUp from '../routes/guest/SignUp'
import Onboarding from '../routes/onboarding/Onboarding'
import Auth from '../routes/auth/Auth'
import ForgotPassword from '../routes/guest/ForgotPassword'
import InviteCreateSession from '../routes/guest/InviteCreateSession'
import NotFound from '../routes/guest/NotFound'
import Profile from '../routes/profile/Profile'
import ProfileEdit from '../routes/profile/ProfileEdit'
import Team from '../routes/admin/Team'
import Chat from '../routes/chat/chat'
import PublicChat from '../routes/public/chat'
import Conversations from '../routes/conversations/Conversations'
import Accounts from '../routes/admin/Accounts'
//import Homepage from '../routes/homepage/Homepage'
import KnowledgeBase from '../routes/knowledgeBase/KnowledgeBase'

const AppRouter = () => {
  const { user } = useAppSelector((state) => state.profile)

  return (
    <Routes>
      <Route path="/" element={user?.id ? <MainLayout /> : <Navigate replace to="/signin" />} />
      <Route element={<GuestLayout />}>
        <Route path="signin" element={user.id ? <Navigate to={'/'} /> : <SignIn />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="onboarding" element={<Onboarding />} />
        <Route path="auth" element={<Auth />} />
        <Route path="invite" element={<InviteCreateSession />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="/chat" element={<PublicChat />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route element={<MainLayout />}>
        <Route index element={<Conversations />} />
        <Route path="profile" element={<Profile />} />
        <Route path="profile/edit" element={<ProfileEdit />} />
        <Route path="team" element={<Team />} />
        {/*<Route path="agent" element={<AIAgent />} />*/}
        <Route path="conversations" element={<Conversations />} />
        <Route path="conversations/:conversationId" element={<Chat />} />
        <Route path="admin/accounts" element={<Accounts />} />
        <Route path="storage" element={<KnowledgeBase />} />
      </Route>
    </Routes>
  )
}

export default AppRouter
