import React from 'react'
import { useLocation } from 'react-router-dom'
import {
  ABOUT_IONI_ROUTE,
  ACCOUNTS,
  ADMIN_USERS_ROUTE,
  AI_HELPER_EDIT_DATA_ROUTE,
  AI_HELPER_UPDATE_DATA_ROUTE,
  CHROMA_TEST_ROUTE,
  CONVERSATIONS,
  CREATE_AI_ASSISTENT,
  DASHBOARD_ROUTE,
  KNOWLEDGE_BASE,
  PROFILE_ROUTE,
  TICKETS_ROUTE,
} from '../../config/consts'
import AiHelperHeader from '../../components/AIHelperHeader/aiHelperHeader'
import KnowledgeBasePageHeader from '../../routes/knowledgeBase/components/KnowledgeBasePageHeader/KnowledgeBasePageHeader'
import AIAgentPageHeader from '../../routes/aiAgent/components/AIAgentPageHeader/AIAgentPageHeader'

const header = [
  {
    path: ADMIN_USERS_ROUTE,
    render: <h1>My Team</h1>,
  },
  {
    path: PROFILE_ROUTE,
    render: <h1>My Profile</h1>,
  },
  {
    path: DASHBOARD_ROUTE,
    render: <h1>Dashboard</h1>,
  },
  {
    path: TICKETS_ROUTE,
    render: <h1>Tickets</h1>,
  },
  {
    path: AI_HELPER_UPDATE_DATA_ROUTE,
    render: <AiHelperHeader title="Add data" />,
  },
  {
    path: AI_HELPER_EDIT_DATA_ROUTE,
    render: <AiHelperHeader title="Edit data" />,
  },
  {
    path: CHROMA_TEST_ROUTE,
    render: <h1>AI Test Page</h1>,
  },
  {
    path: CREATE_AI_ASSISTENT,
    render: <AIAgentPageHeader title="AI Agent" />,
  },
  {
    path: KNOWLEDGE_BASE,
    render: <KnowledgeBasePageHeader title="Knowledge Base" />,
  },
  {
    path: CONVERSATIONS,
    render: <AiHelperHeader title="Inspections History" />,
  },
  {
    path: ACCOUNTS,
    render: <AiHelperHeader title="Accounts" />,
  },
  {
    path: ABOUT_IONI_ROUTE,
    render: <AiHelperHeader title="Welcome to SafetyLens" />,
  },
]
const LeftHeader = () => {
  const location = useLocation()

  return <>{header.find((item) => item.path === location.pathname)?.render}</>
}

export default LeftHeader
