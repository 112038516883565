import React, { useEffect, forwardRef, LegacyRef, memo, useState, useCallback } from 'react'
import { Button, Form, notification, Space } from 'antd'
import { FileRejection, useDropzone } from 'react-dropzone'
import { Message } from '../../types/storage.type'
import { Conversation } from '../../types/conversation.type'
import ReactMarkdownContent from '../../routes/chat/components/ReactMarkdownContent/ReactMarkdownContent'
import AddImageByLinkModal from '../UI/Modals/AddImageByLinkModal'
import AddImagesPopover from '../../routes/chat/components/AddImagesPopover/AddImagesPopover'
import SelectedImages from '../../routes/chat/components/SelectedImages/SelectedImages'
import './chat.less'

export interface ConversationMessageItem {
  message?: string
  linksImages?: string[]
  fileImages?: File[]
}

interface Props {
  onSend: (values: ConversationMessageItem) => void
  data: Conversation | { id: string | undefined; messages: Array<Message> }
  isFetching: boolean
  isEditable: boolean
  successMessageSend?: boolean
  withFileAttachment?: boolean
}

const Chat = forwardRef(function Chat(
  { onSend, data, isFetching, isEditable, successMessageSend, withFileAttachment }: Props,
  ref: LegacyRef<HTMLDivElement>,
) {
  const [form] = Form.useForm()

  /** State */
  const [uploadedImages, setUploadedImages] = useState<Array<File | string>>([])

  const handleFileUpload = (files: File[]) => {
    const validFiles = files.filter(
      (file) =>
        ['image/png', 'image/jpeg', 'image/webp', 'image/gif'].includes(file.type) && file.size <= 20 * 1024 * 1024,
    )
    const nonDuplicateFiles = validFiles.filter(
      (file) => !uploadedImages.some((uploadedFile: any) => uploadedFile.name === file.name),
    )
    if (uploadedImages.length + nonDuplicateFiles.length <= 5) {
      setUploadedImages((prev) => [...prev, ...nonDuplicateFiles])
    } else {
      notification.error({
        message: 'Maximum number of added images cannot exceed 5',
      })
    }
    if (nonDuplicateFiles.length < validFiles.length) {
      notification.warning({
        message: 'Some files were not uploaded because they have the same name',
      })
    }
  }

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      handleFileUpload(acceptedFiles)
    },
    [handleFileUpload, uploadedImages],
  )

  const onDropRejected = (rejectedFiles: FileRejection[]) => {
    notification.error({
      message: rejectedFiles[0].errors[0].message,
    })
  }

  /** Use custom hooks */
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/webp': [],
      'image/gif': [],
    },
    maxSize: 20 * 1024 * 1024,
    noClick: true,
  })

  const handleImageLinkAdd = useCallback((imageLink: string) => {
    /** Validate image link before adding */
    setUploadedImages((prev) => [...prev, imageLink]) // Placeholder file object for preview
  }, [])

  const removeImage = useCallback((index: number) => {
    setUploadedImages((prev) => prev.filter((_, i) => i !== index))
  }, [])

  const onSubmit = (values: { newMessage: string }) => {
    const request: ConversationMessageItem = {
      message: values.newMessage,
      linksImages: uploadedImages.filter((image) => typeof image === 'string' && image) as string[],
      fileImages: uploadedImages.filter((image) => typeof image !== 'string' && image) as File[],
    }

    onSend(request)
  }

  useEffect(() => {
    if (!isFetching) {
      form.resetFields()
    }
  }, [isFetching])

  useEffect(() => {
    if (successMessageSend && !isFetching) {
      setUploadedImages([])
      form.resetFields()
    }
  }, [successMessageSend, isFetching])

  return (
    <Space direction="vertical" size={'middle'} style={{ display: 'flex' }}>
      {isEditable && !successMessageSend && <div className="skeleton-drag-and-drop" {...getRootProps()} />}
      <div id="chat-container" style={{ height: !successMessageSend ? '' : '80vh' }} ref={ref}>
        <ul>
          {data?.messages?.map(
            (message: Message, index) =>
              message.role !== 'system' && <ReactMarkdownContent message={message} key={index} />,
          )}
        </ul>
      </div>
      {isEditable && !successMessageSend && (
        <Form form={form} layout={'vertical'} onFinish={onSubmit} disabled={isFetching}>
          <div className="form-item-container">
            {uploadedImages?.length > 0 ? <SelectedImages images={uploadedImages} removeImage={removeImage} /> : null}
            <input {...getInputProps()} />
            {withFileAttachment && (
              <AddImagesPopover
                isFullSelectedImages={uploadedImages.length === 5}
                onDrop={onDrop}
                disabled={isFetching}
              />
            )}
          </div>
          <Button
            disabled={!uploadedImages.length}
            id="form-button"
            block
            type="primary"
            htmlType="submit"
            loading={isFetching}
          >
            Create observation
          </Button>
          <p className="disclaimer-text">
            SafetyLens safety inspections may not always be accurate. Please verify critical information.
          </p>
        </Form>
      )}
      {withFileAttachment && <AddImageByLinkModal handleImageLinkAdd={handleImageLinkAdd} />}
    </Space>
  )
})

export default memo(Chat)
