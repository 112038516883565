import React, { useEffect, useRef } from 'react'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { SerializedError } from '@reduxjs/toolkit'
import ChatHeader from './ChatHeader'
import ChatComponent, { ConversationMessageItem } from '../../components/Chat/Chat'
import { useErrorMessage } from '../../hooks/useErrorMessage'
import { GetChatRequest } from '../../types/public.type'
import { ConversationImageUrl, ConversationMessageRequestBody } from '../../types/conversation.type'
import { useLazyChatQuery } from '../../store/api/pubic.api'
import { useUploadFileMutation } from '../../store/api/uploads.api'

import './publicChat.less'
import { useAppSelector } from '../../hooks/appHook'

const Chat = () => {
  const { account } = useAppSelector((state) => state.account)
  const { currentStorageId } = useAppSelector((state) => state.storage)
  const { currentAgentId } = useAppSelector((state) => state.agents)

  const listRef = useRef<HTMLDivElement>(null)

  /** Storage actions */
  const [postMessageHandler, { data, error, isFetching, isSuccess }] = useLazyChatQuery()
  const [uploadFile, uploadFileMutationResult] = useUploadFileMutation()

  /** Response message handlers */
  useErrorMessage('Something went wrong', error)
  useErrorMessage('Something went wrong', uploadFileMutationResult.error)

  const scrollToBottom = () => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight
    }
  }

  const uploadImage = async (image: File, chatRequestProps: GetChatRequest) => {
    const formData = new FormData()
    formData.append('user', JSON.stringify(chatRequestProps))
    formData.append('file', image)

    const result: { data: ConversationImageUrl } | { error: FetchBaseQueryError | SerializedError } = await uploadFile({
      body: formData,
    })

    if ('data' in result) {
      return result
    }
    return null
  }

  const send = async (values: ConversationMessageItem) => {
    const chatRequestProps = {} as GetChatRequest

    chatRequestProps.accountId = account?.id

    if (currentStorageId) {
      chatRequestProps.storageId = currentStorageId
    }

    if (currentAgentId) {
      chatRequestProps.agentId = currentAgentId
    }

    console.log({ chatRequestProps, currentStorageId })

    if (data?.id) {
      chatRequestProps.conversationId = data.id
    }

    const message: ConversationMessageRequestBody = {
      role: 'user',
      content: [],
    }

    if (values.message) {
      message.content.push({
        type: 'text',
        text: values.message,
      })
    }

    if (values.linksImages?.length) {
      values.linksImages.forEach((url) => {
        message.content.push({
          type: 'image_url',
          image_url: {
            url,
            s3Uri: '',
          },
        })
      })
    }

    let uploadsImageResult: Array<{ data: ConversationImageUrl }> = []

    if (values.fileImages?.length) {
      try {
        const uploadPromises = values.fileImages.map((image: File) => uploadImage(image, chatRequestProps))

        const results = await Promise.all(uploadPromises)

        uploadsImageResult = results.filter(Boolean) as Array<{ data: ConversationImageUrl }>
      } catch (err) {
        console.log('Uploads images error', err)
      }
    }

    if (uploadsImageResult?.length) {
      uploadsImageResult.forEach(({ data }) => {
        message.content.push({
          type: 'image_url',
          image_url: data,
        })
      })
    }

    chatRequestProps.newMessage = message

    postMessageHandler(chatRequestProps)
  }

  useEffect(() => {
    scrollToBottom()
  }, [data])

  return (
    <>
      <ChatHeader />
      <div className="public-chat-container">
        <ChatComponent
          ref={listRef}
          isEditable
          onSend={send}
          withFileAttachment
          isFetching={isFetching}
          successMessageSend={isSuccess}
          data={data || { id: undefined, messages: [] }}
        />
      </div>
    </>
  )
}

export default Chat
